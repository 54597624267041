declare global {
  interface Window {
    checksumai: any;
  }
}

// Adds a dynamic script tag to download/initialize Checksum
// Making everything optional with respect to commands from Checksum
// so that the app doesn't crash in case it is undefined
export const initChecksum = () => {
  const script = document.createElement('script');
  script.onload = function () {
    window.checksumai = window.checksumai || {};
    window.checksumai?.init(import.meta.env.VITE_APP_POSTHOG_CLIENT as string);
  };
  script.src = 'https://cdn.checksum.ai/checksum-sdk-min-0.2.js';
  document.body.append(script);
};

export const resetChecksum = () => {
  window.checksumai?.reset();
};

export const identifyUserChecksum = (userId: string) => {
  window.checksumai?.identify(userId);
};

export const captureConfigChecksum = (config: any) => {
  window.checksumai?.capture('network_request', config);
};
